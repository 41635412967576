<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <Navigation />
    <!-- 背景生成 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>
        <div class="ct_box flex">
          <div class="form_upload_box">
            <img src="@/assets/ai/paint22.png" alt="" class="title">
            <p class="himt">提示:(背景生成功能)暂支持使用png格式图片</p>
            <ai_upload_img accept=".png" @getImg="getImg" />
            <ai_cascader @getCascader="getCascader" :style_list="style_list" />
            <button class="create_but" @click="FileData">生成内容</button>
            <ai_img :type="2" contentName='生成后的图片' :contentData="contentData" />
            <aiNum title="计费规则：本次背景生成预计消费3元" :visible="aiNumShow" @close="aiNumShow = false" @postData="postData" />
            <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
          </div>
          <aiNav />

        </div>

      </div>


    </div>
    <footerBot />
  </div>
</template>

<script>
import ai_upload_img from '@/components/ai_upload_img'
import ai_img from '@/components/ai_img'
import aiNav from '@/components/aiNav'
import ai_cascader from '@/components/ai_cascader.vue'
export default {
  name: '',
  created() { },
  mounted() {
    this.getUserInfo()
  },
  components: {
    aiNav,
    ai_upload_img,
    ai_img,
    ai_cascader
  },
  data() {
    return {
      fileList: [],
      style_list: [
        {
          value: '基础',
          label: '基础',
          children: [
            {
              label:'白色背景',
              value:'白色背景'
            },
            {
              label:'灰色背景',
              value:'灰色背景',
            },
            {
              label:'蓝色背景',
              value:'蓝色背景',
            },
            {
              label:'客厅家居',
              value:'客厅家居',
            },
            {
              label:'木制台面',
              value:'木制台面',
            },
            {
              label:'户外草坪',
              value:'户外草坪',
            },
            {
              label:'缤纷花卉',
              value:'缤纷花卉',
            },
            {
              label:'咖啡厅',
              value:'咖啡厅',
            },
            {
              label:'大理石纹',
              value:'大理石纹',
            },
            {
              label:'枫林落叶',
              value:'枫林落叶',
            },
            {
              label:'山石光影',
              value:'山石光影',
            },
            {
              label:'阳光沙滩',
              value:'阳光沙滩',
            },
            
          ]
        },
        {
          value: '纯色',
          label: '纯色',
          children: [
            {
              label:'杏仁色',
              value:'杏仁色',
            },
            {
              label:'蜜桃粉',
              value:'蜜桃粉',
            },
            {
              label:'金秋黄',
              value:'金秋黄',
            },
            {
              label:'青草绿',
              value:'青草绿',
            },
            {
              label:'晚霞紫',
              value:'晚霞紫',
            },
            {
              label:'月牙白',
              value:'月牙白',
            },
            {
              label:'深灰色',
              value:'深灰色',
            },
            {
              label:'烟雨灰',
              value:'烟雨灰',
            },
            {
              label:'浅灰色',
              value:'浅灰色',
            },
            {
              label:'磨砂蓝',
              value:'磨砂蓝',
            },
            {
              label:'青花蓝',
              value:'青花蓝',
            },
            {
              label:'天蓝色',
              value:'天蓝色',
            },
            {
              label:'墨玉黑',
              value:'墨玉黑',
            },
            {
              label:'古铜棕',
              value:'古铜棕',
            },
            {
              label:'朱砂红',
              value:'朱砂红',
            },
            {
              label:'秋阳橙',
              value:'秋阳橙',
            },
            {
              label:'渐变色',
              value:'渐变色',
            },
          ]
        },
        {
          value: '台面',
          label: '台面',
          children:[
            {
              label: '浅木色台面',
              value: '浅木色台面',
            },
            {
              label: '家居大理石',
              value: '家居大理石',
            },
            {
              label: '大理石纹',
              value: '大理石纹',
            },
          ]
        },
        {
          value: '室内',
          label: '室内',
          children: [
            {
              label: '牡丹',
              value: '牡丹',
            },
            {
              label: '厨房木板',
              value: '厨房木板',
            },
            {
              label: '地板光斑',
              value: '地板光斑',
            },
          ]
        },
        {
          value: '花',
          label: '花',
          children: [
            {
              label: '牡丹',
              value: '牡丹',
            },
            {
              label: '樱花',
              value: '樱花',
            },
            {
              label: '白色鲜花',
              value: '白色鲜花',
            },
          ]
        },
        {
          value: '绿植',
          label: '绿植',
          children: [
            {
              label: '草地植物',
              value: '草地植物',
            },
            {
              label: '户外草坪',
              value: '户外草坪',
            },
            {
              label: '树丛葱郁',
              value: '树丛葱郁',
            },
          ]
        },
        {
          value: '岩石',
          label: '岩石',
          children: [
            {
              label: '金秋岩石',
              value: '金秋岩石',
            },
            {
              label: '溪石悠谭',
              value: '溪石悠谭',
            },
            {
              label: '暗黑岩石',
              value: '暗黑岩石',
            },
          ]
        },
        {
          value: '液体',
          label: '液体',
          children: [
            {
              label: '水波粼粼',
              value: '水波粼粼',
            },
            {
              label: '梦幻粉波',
              value: '梦幻粉波',
            },
            {
              label: '海辉夕映',
              value: '海辉夕映',
            },
          ]
        },
        {
          value: '景观',
          label: '景观',
          children: [
            {

              label: '蜿蜒山脉',
              value: '蜿蜒山脉',
            },
            {
              label: '雪巅苍穹',
              value: '雪巅苍穹',
            },
            {
              label: '雪域星辉',
              value: '雪域星辉',
            },
          ]
        },
        {
          value: '季节',
          label: '季节',
          children: [
            {

              label: '秋色宜人',
              value: '秋色宜人',
            },
            {
              label: '白雪皑皑',
              value: '白雪皑皑',
            },
            {
              label: '春意盎然',
              value: '春意盎然',
            },
          ]
        },
        {
          value: '丝滑布料',
          label: '丝滑布料',
          children: [
            {

              label: '白色丝绸',
              value: '白色丝绸',
            },
            {
              label: '蓝色绸缎',
              value: '蓝色绸缎',
            },
            {
              label: '粉色绸缎',
              value: '粉色绸缎',
            },
          ]
        },
        {
          value: '节日庆典',
          label: '节日庆典',
          children: [
            {

              label: '圣诞缤纷',
              value: '圣诞缤纷',
            },
            {
              label: '节日礼遇',
              value: '节日礼遇',
            },
            {
              label: '绿松雅韵',
              value: '绿松雅韵',
            },
          ]
        },
        {
          value: '视觉效果',
          label: '视觉效果',
          children: [
            {

              label: '都市夜景',
              value: '都市夜景',
            },
            {
              label: '落日余晖',
              value: '落日余晖',
            },
          ]
        },
        {
          value: '户外场景',
          label: '户外场景',
          children: [
            {

              label: '城市街道',
              value: '城市街道',
            },
            {
              label: '绿野公园',
              value: '绿野公园',
            },{
              label: '柏油沥青',
              value: '柏油沥青',
            },
          ]
        },
        {
          value: '散影效果',
          label: '散影效果',
          children: [
            {

              label: '梦幻粉',
              value: '梦幻粉',
            },
            {
              label: '高贵银',
              value: '高贵银',
            },
            {
              label: '玫瑰金',
              value: '玫瑰金',
            },
          ]
        },
        {
          value: '沙滩',
          label: '沙滩',
          children: [
            {

              label: '湿沙滩',
              value: '湿沙滩',
            },
            {
              label: '干沙滩',
              value: '干沙滩',
            },
            {
              label: '椰子树',
              value: '椰子树',
            },
          ]
        },
        {
          value: '草坪',
          label: '草坪',
          children: [
            {

              label: '绿草茵茵',
              value: '绿草茵茵',
            },
            {
              label: '公园草坪',
              value: '公园草坪',
            },
            {
              label: '庭院草坪',
              value: '庭院草坪',
            },
          ]
        },
      ],
      contentData: {
        origin_url: '',
        style: '',
        // cover_url:''
        output_content: '',
      }, //内容
      fullscreenLoading: false,
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    getImg(url) {
      this.contentData.origin_url = url
      console.log('contentData', this.contentData);
    },
    getCascader(value) {
      this.contentData.style = value
      console.log('contentData', this.contentData);
    },
    // 获取用户信息
    getUserInfo() {
      this.curlGet('/api/user/info').then((res) => {
        if (res.data.code) {
          this.$user_info = res.data.data
          this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
        }
      })
    },
    FileData() {
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
      }
    },
    postData() {
      this.aiNumShow = false
      this.fullscreenLoading = true
      let data = {}
      data.mobile = this.$user_info.mobile
      data.style = this.contentData.style
      for (const key in this.contentData) {
        if (key != 'output_content') {
          console.log('index:', key);
          data[key] = this.contentData[key]
        }
      }
      this.curlPost('file/drawing_background', data).then(res => {
        console.log(res);
        if (res.data.code) {
          this.contentData.output_content = res.data.data.output_content

          // 扣费
          this.curlPost('/api/users/money_log/spend', {
            ai_id: res.data.data.id,
            amount: res.data.data.money,
          }).then(res => {
            if (res.data.code) {
              this.getUserInfo()
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
        this.fullscreenLoading = false
      })
    }

  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>